import { Directive, HostListener, inject, Input } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { EntriesService } from 'superlikers-microsites-api'

@Directive({
  selector: '[appClicks]',
  standalone: true
})
export class ClicksDirective {
  @Input('appClicks') name = 'No text provided'

  entriesService = inject(EntriesService)

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent) {
    event.preventDefault()

    const properties = { name: this.name }

    const response$ = this.entriesService.sendExternal({ event: 'Clicks', properties, category: this.name })
    await firstValueFrom(response$)
  }
}
